import React from 'react';
import { Container, withStyles } from '@material-ui/core';
import { useInfiniteProducts } from 'src/hooks/queries/useProducts';
import { IOrderProductAdmin, ProductResponse } from 'src/services/Products';
import { ClassNameMap } from 'src/types/Dom';
import TablePaginator from 'src/components/common/Table/TablePaginator';
import { ColumnType } from 'src/types/TablePaginator';
import { formatCurrency } from 'src/utils/formatNumber';

const PRODUCT_COLUMNS: ColumnType[] = [
  { field: 'name', title: 'Name' },
  { field: 'supplierName', title: 'Supplier' },
  { field: 'description', title: 'Description' },
  { field: 'minimumOrderQuantity', title: 'Minimum Order Quantity' },
  {
    field: 'listPrice',
    title: 'List Price',
    render: (value: number) => formatCurrency(value),
  },
  {
    field: 'currentCost',
    title: 'Current Cost',
    render: (value: number) => formatCurrency(value),
  },
  {
    field: 'currentCostWithGuaranteedRebates',
    title: 'Current Cost with Guaranteed Rebates',
    render: (value: number) => formatCurrency(value),
  },
  {
    field: 'currentCostWithAllRebates',
    title: 'Current Cost with All Rebates',
    render: (value: number) => formatCurrency(value),
  },
  {
    field: 'legacyPrice',
    title: 'Legacy Price',
    render: (value: number) => formatCurrency(value),
  },
  {
    field: 'legacyPlusPrice',
    title: 'Legacy Plus Price',
    render: (value: number) => formatCurrency(value),
  },
  {
    field: 'flexPrice',
    title: 'Flex Price',
    render: (value: number) => formatCurrency(value),
  },
  {
    field: 'launchPrice',
    title: 'Launch Price',
    render: (value: number) => formatCurrency(value),
  },
  {
    field: 'ascendPrice',
    title: 'Ascend Price',
    render: (value: number) => formatCurrency(value),
  },
];

export const ProductsTable: React.FC<ClassNameMap & { editProduct?: (product: IOrderProductAdmin) => void }> = ({
  classes,
  editProduct,
}) => {
  const { data, isLoading, isError, fetchNextPage } = useInfiniteProducts();

  return (
    <Container classes={classes} data-testid="orderProducts">
      <TablePaginator
        columns={PRODUCT_COLUMNS}
        data={data?.pages?.flatMap(({ data: pageData }: ProductResponse) => pageData) ?? []}
        total={data?.pages ? data.pages[0].meta.totalCount : 0}
        isLoading={isLoading}
        isError={isError}
        fetchNextPage={fetchNextPage}
        onClickRow={editProduct}
      />
    </Container>
  );
};

export default withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  maxWidthLg: {
    maxWidth: 'unset',
  },
})(ProductsTable);

import React from 'react';
import { MenuItem } from '@material-ui/core';
import { ALL_MEDICAL_CREDENTIALS, MEDICAL_CREDENTIALS } from 'src/pages/AccountSetting/MoreDetails';
import { EHRSelect } from '../ui/v1';

export interface CredentialsDropdownProps {
  value?: string;
  onChange: (event: any) => void;
  hasErrors?: boolean;
  withOldLicenses?: boolean;
  asFilter?: boolean;
  disabled?: boolean;
}

const CredentialsDropdown = (props: CredentialsDropdownProps) => {
  const { value, onChange, withOldLicenses = false, hasErrors = false, asFilter = false, disabled = false } = props;

  const getCredentialsList = () => {
    const credentials = withOldLicenses ? ALL_MEDICAL_CREDENTIALS : MEDICAL_CREDENTIALS;
    const credentialsWithFilter = asFilter ? ['All', ...credentials] : credentials;
    return value && !MEDICAL_CREDENTIALS.includes(value) ? [value, ...credentialsWithFilter] : credentialsWithFilter;
  };

  const handleValue = (credential: string) => {
    if (!credential) {
      return 'None of these';
    }
    if (credential === 'All') {
      return undefined;
    }
    return credential;
  };

  return (
    <EHRSelect
      dataCy="select-licenses"
      data-testid="select-licenses"
      name="Credentials"
      value={value}
      onChange={onChange}
      label="Credentials"
      fullWidth
      error={hasErrors}
      helperText={hasErrors ? 'Error updating credentials' : ''}
      disabled={disabled}
    >
      {getCredentialsList().map((credential) => (
        <MenuItem value={handleValue(credential)} key={credential}>
          {credential}
        </MenuItem>
      ))}
    </EHRSelect>
  );
};

export default CredentialsDropdown;
